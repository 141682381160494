html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
  overflow: hidden;
}

body,
html,
#root {
  height: 100%;
  background: #fff;

  margin-bottom: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s;
}

.carousel-root div,
.carousel-root ul,
.carousel-root li {
  height: 100%;
}

.carousel-root img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: middle;
}
